body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: #0e2144;
  color: #FFFFFF;
  padding: 10px 20px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px;
}

.navbar-left a:hover {
  text-decoration: underline; /* Fare linkin üzerine geldiğinde alt çizgi ekler */
}

.navbar-left, .navbar-center, .navbar-right {
  display: flex;
  align-items: center;
}

button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: transparent;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

button.selectwinner {
  border-color: #FFFFFF;
  color: #FFFFFF;
}

button.participate {
  border-color: #00FD46;
  color: #00FD46;
}

button.investor {
  border-color: #FF0000;
  color: #FF0000;
}

button:hover {
  background-color: #FFFFFF;
  color: #283242;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 0 20px 0;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #0e2144;
  color: #FFFFFF;
  text-align: center;
  padding: 10px 0;
}


.box {
  background-color: #0e2144;
  color: #FFFFFF;
  padding: 10px;
  margin: 20px 20px 20px 20px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 300px;
  height: 200px; 
}

.box p {
  margin: 10px;
  width: 100%;
}

.box p:nth-of-type(1) {
  padding: 20px 0 10px 0;
  margin: 5px 5px 5px 5px;
  text-align: center;
  align-items: center;
  border: 3px solid #00FD46;
  border-radius: 5px;
  color: #00FD46;
}

.box p:nth-of-type(2) {
  padding: 20px 0 5px 0;
  margin: 5px 5px 5px 5px;
}

.box p:nth-of-type(3) {
  color: #FF0000;
}